<template>
    <card-list-item @click="$emit('click')" :class="{'active': active}">
        <template v-slot:icon><i class="fa-fw fa-hotel" :class="{'fas': active, 'far': !active}"></i></template>
        <template v-slot:content>
            <div class="tile-title">{{ hotel.title }}</div>
        </template>
        <template v-slot:details>
            <div class="details-rollout">
                <div class="data mt-1">
                    <data-row mini title="ID">{{ hotel.id }}</data-row>
                </div>
            </div>
        </template>
    </card-list-item>
</template>

<script>
    import Toolbar from "../TIER/components/Toolbar";
    import DataRow from "../TIER/components/DataRow";
    import CardListItem from "../TIER/components/CardListItem";

    export default {
        name: 'TripHotelListItem',
        components: {CardListItem, DataRow, Toolbar},
        props: {
            card: {
                required: true
            },
            hotel: {
                type: Object,
                required: true
            },
            active: {
                type: Boolean,
                default: false
            }
        },
        data: function () {
            return {
                details: false
            }
        }
    }
</script>

<style lang="scss" scoped>

    li {
        color: darken($t-primary, 10);

        .tile-title {
            font-size: .9rem;
            font-weight: 700;
        }

        padding: 0.5rem 0;

        .details-rollout {
            margin-top: 0.5rem;
        }
    }

    .drag-handle {
        cursor: ns-resize;
    }
</style>
