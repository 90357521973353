<template>
    <div class="mh-card-toolbar">
        <div class="left"><slot/></div>
        <div class="right"><slot name="right"/></div>
        <div class="clear"></div>
    </div>
</template>

<script>
    export default {
        name: "CardToolbar"
    }
</script>

<style lang="scss" scoped>
    .mh-card-toolbar {
        background: #f3f3f3;
        border-bottom: 1px solid #dee2e6;

        padding: 3px 0.4rem 0 0.6rem;
        min-height: 30px;

        overflow: hidden;

        .left {
            float: left;
        }

        .right {
            float: right;
            text-align: right;
        }

        .clear::after {
            content: "";
            clear: both;
            display: table;
        }

        button {
            padding: 0.2rem 0.4rem;
            line-height: normal;
            font-size: 0.82rem;

            background-color: transparent;
            border: 0;
            color: darken($t-primary, 10);
            border-radius: 0.2rem;
            margin-right: 0.2rem;
            cursor: pointer;

            &.icon-only i {
                margin-right: 0 !important;
            }

            &.btn-warning:hover {
                background: #d97d07 !important;
            }

            i {
                color: $t-primary;
                margin-right: 0.4rem;
            }

            &:hover {
                background: $t-primary;
                color: white !important;

                i {
                    color: white !important;
                }
            }

            &:focus {
                outline-color: $t-primary;
            }
        }
    }
</style>
