<template>
    <card-list-item @click="action" :class="{'clickable': clickable}">
        <template v-slot:icon>
            <i class="fa-fw fas" :class="icon" v-if="icon"></i>
        </template>
        <template v-slot:content>
            <div class="title">
                {{title}}
            </div>
        </template>
        <template v-slot:badge v-if="badge">
            <b-tag>{{badge}}</b-tag>
        </template>
    </card-list-item>
</template>

<script>
    import CardListItem from "./CardListItem";
    export default {
        name: 'SubheaderListItem',
        components: {CardListItem},
        props: {
            title:  String,
            icon: String,
            badge: [String, Number],
            clickable: Boolean
        },
        methods: {
            action: function() {
                if (this.clickable) this.$emit('action');
            }
        }
    }
</script>

<style lang="scss" scoped>
    li {
        background-color: darken($t-primary, 10) !important;
        border-bottom: 1px solid darken($t-primary, 10) !important;
        padding: 0.2rem 0;

        cursor: default !important;

        .title {
            font-family: 'Montserrat', sans-serif;
            color: white;
            text-transform: uppercase;
            font-size: 0.75rem;
            font-weight: bold;
            letter-spacing: 0.5px;
            padding: 5px 0 3px;
        }

        i {
            font-size: 0.8rem !important;
            color: white;
        }

        &:hover {
            background-color: darken($t-primary, 10) !important;
        }

        &.clickable {
            cursor: pointer;

            &:hover {
                background-color: darken($t-primary, 5) !important;
            }
        }

        .badge {
            margin-top: 2px;
            margin-right: 0
        }
    }
</style>
