<template>
    <div>
        <card-header title="Hotels" :subheader="trip.title" icon="fa-hotel"/>
        <card-toolbar>
            <button @click="$openCard('new-trip-hotel', {tripId: props.tripId}, card)"><i class="fas fa-plus mr-2"/>New Hotel</button>
        </card-toolbar>

        <card-body>
            <card-list>
                <subheader-list-item title="Hotels" icon="fas fa-hotel"/>
                    <trip-hotel-list-item
                        :hotel="hotel"
                        :card="card"
                        v-for="hotel in hotels"
                        :active="child && child.definition.component === 'trip-hotel' && child.props.hotelId === hotel.id.toString()"
                        @click="$openCard('trip-hotel', {hotelId: hotel.id, tripId: hotel.trip_id}, card)"
                    />
            </card-list>
        </card-body>
    </div>
</template>

<script>
    import CardHeader from "@/TIER/components/CardHeader";
    import CardList from "@/TIER/components/CardList";
    import CardToolbar from "@/TIER/components/CardToolbar";
    import SubheaderListItem from "@/TIER/components/SubheaderListItem";
    import CardBody from "@/TIER/components/CardBody";

    import TripHotelListItem from "@/components/TripHotelListItem";

    import {client as http} from '@/http_client';

    export default {
        props: ['card', 'child', 'props'],
        components: {TripHotelListItem, CardBody, SubheaderListItem, CardToolbar, CardList, CardHeader},
        data: function() {
            return {
                hotels: [],
                trip: {}
            }
        },
        methods: {
            loadHotels: function(force) {
                this.$emit('loading', true);
                http.get('/api/trips/' + this.props.tripId + '/hotels', {force}).then(response => {
                    this.hotels = response.data;
                    this.$emit('loading', false);
                });
            },
            loadTrip: function() {
                this.$emit('loading', true);
                http.get('/api/trips/' + this.props.tripId).then(response => {
                    this.trip = response.data;
                });
            },
            reload: function() {
                this.loadHotels(true);
            }
        },
        watch: {
            'props.tripId': function() {
                this.loadHotels();
            }
        },
        mounted() {
            this.loadHotels();
        }
    };
</script>

<style lang="scss" scoped>

</style>
